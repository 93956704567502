import { ChartData } from 'chart.js';
import moment from 'moment-timezone';
import { LegacyInsightsSummary } from '../../../../../../app/ApiGen';
import { getCost } from '../../../../../../utils/electricityCost';

const convertTokWhRelatedFormat = (value: number) => Number((value / 1000).toFixed(0)).toLocaleString();

export const getInsightTotals = (data: LegacyInsightsSummary[]) => {
  const { powerUsage, costs, duration, sessions, carbonOffset, totalCarbonUsage } = data.reduce(
    (acc, insight) => ({
      ...acc,
      powerUsage: acc.powerUsage + insight.powerUsage,
      costs: acc.costs + (getCost(insight.costs) ?? 0),
      duration: acc.duration + insight.duration,
      sessions: acc.sessions + insight.sessions,
      carbonOffset: acc.carbonOffset + insight.carbonOffset,
      totalCarbonUsage: (acc.totalCarbonUsage ?? 0) + (insight.totalCarbonUsage ?? 0),
    }),
    {
      powerUsage: 0,
      costs: 0,
      duration: 0,
      sessions: 0,
      carbonOffset: 0,
      totalCarbonUsage: undefined as number | undefined,
    },
  );

  return {
    powerUsage: convertTokWhRelatedFormat(powerUsage),
    costs: Number(costs.toFixed(0)).toLocaleString(),
    duration: duration.toLocaleString(),
    sessions: sessions.toLocaleString(),
    carbonOffset: convertTokWhRelatedFormat(carbonOffset),
    totalCarbonUsage,
  };
};

const chartType = ['powerUsage', 'costs', 'sessions', 'carbonOffset', 'totalCarbonUsage'];

const getChartLabels = (carbonUsageUnit: string) => ({
  powerUsage: 'Electricity usage (kWh)',
  costs: 'Electricity cost ($)',
  sessions: 'Sessions',
  carbonOffset: 'Carbon offset (kg)',
  totalCarbonUsage: `Carbon use (${carbonUsageUnit})`,
});

const carbonUsageForGraph = (params: { useKilogramForCarbonUsage: boolean; totalCarbonUsage?: number }) => {
  const { useKilogramForCarbonUsage, totalCarbonUsage } = params;
  if (totalCarbonUsage) {
    if (useKilogramForCarbonUsage) {
      return totalCarbonUsage / 1000;
    }
    return totalCarbonUsage;
  }
  return 0;
};

export const getChartData = (
  tabId: number,
  insights: LegacyInsightsSummary[],
  backgroundColor: string,
): ChartData<'bar'> => {
  const type = chartType[tabId];

  const labels =
    insights.length > 14
      ? insights.map(({ startDate }) => moment(startDate).format('D MMM'))
      : insights.map(({ startDate }) => moment(startDate).format('ddd'));

  const totalCarbonUsageForAllSessions = getInsightTotals(insights).totalCarbonUsage;

  const useKilogramForCarbonUsage =
    totalCarbonUsageForAllSessions !== undefined && totalCarbonUsageForAllSessions >= 1000;

  const carbonUseUnit = useKilogramForCarbonUsage ? `kg` : 'g';

  const data = insights.map((node) => {
    switch (type) {
      case 'powerUsage':
        return node[type] / 1000;
      case 'costs':
        return getCost(node.costs) ?? 0;
      case 'carbonOffset':
        return node[type] / 1000;
      case 'totalCarbonUsage':
        return carbonUsageForGraph({ useKilogramForCarbonUsage, totalCarbonUsage: node[type] });
      default:
        return node[type as keyof LegacyInsightsSummary] as number;
    }
  });

  const chartLabel = getChartLabels(carbonUseUnit);
  const label = chartLabel[type as keyof typeof chartLabel];

  return {
    labels,
    datasets: [
      {
        label,
        data,
        backgroundColor,
        maxBarThickness: 30,
      },
    ],
  };
};
