import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useNavigationView } from '../../../../../design-system';
import { fullStateSelector } from '../../../../../store/root';
import AdvancedView from './Advanced';
import ChargeSchedulingView from './ChargeScheduling';
import { ChargingConfigurationEditor } from './ChargingConfiguration';
import DiagnosticsView from './Diagnostics';
import FirmwareView from './Firmware';
import LoadManagementView from './LoadManagement';
import { LocalLoadManagementDiagnostics } from './LocalLoadManagementDiagnostics';
import LogsView from './Logs';
import { chargePointNav } from './navView';
import { OverviewView } from './Overview';
import { ChargePointProvider } from './Provider';
import SessionsView from './SessionsView';
import { SettingsView } from './Settings';

export const ChargePointView: React.FC = () => {
  useNavigationView(chargePointNav);

  const { path, url } = useRouteMatch<{ id: string }>();
  const { isAdmin, isEvnexAdmin } = useSelector(fullStateSelector);

  return (
    <ChargePointProvider>
      <Switch>
        <Route path={`${path}/overview`}>
          <OverviewView />
        </Route>
        <Route path={`${path}/sessions`}>
          <SessionsView />
        </Route>
        <Route path={`${path}/charge-scheduling`}>
          <ChargeSchedulingView />
        </Route>
        <Route path={`${path}/load-management`}>
          <LoadManagementView />
        </Route>
        {(isEvnexAdmin || isAdmin) && (
          <Route path={`${path}/logs`}>
            <LogsView />
          </Route>
        )}
        <Route path={`${path}/settings`}>
          <SettingsView />
        </Route>
        {(isEvnexAdmin || isAdmin) && (
          <Route path={`${path}/firmware`}>
            <FirmwareView />
          </Route>
        )}
        {(isEvnexAdmin || isAdmin) && (
          <Route path={`${path}/advanced`}>
            <AdvancedView />
          </Route>
        )}
        {isEvnexAdmin && (
          <Route path={`${path}/diagnostics`}>
            <DiagnosticsView />
          </Route>
        )}
        {isEvnexAdmin && (
          <Route path={`${path}/llm-diagnostics`}>
            <LocalLoadManagementDiagnostics />
          </Route>
        )}

        {isEvnexAdmin && (
          <Route path={`${path}/charging-configuration`}>
            <ChargingConfigurationEditor />
          </Route>
        )}

        <Route>
          <Redirect to={`${url}/overview`} />
        </Route>
      </Switch>
    </ChargePointProvider>
  );
};
