import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import { Alert } from '@material-ui/lab';
import * as React from 'react';
import { Section, useWizard } from '../../../../../../design-system';

export const ElectricityPriceStep: React.FC = () => {
  const [isLoadingNextStep, setLoadingNextStep] = React.useState<boolean>(false);
  const { prevStep, nextStep } = useWizard();

  const goToNextStep = React.useCallback(() => {
    setLoadingNextStep(true);
    nextStep();
    setLoadingNextStep(false);
  }, [nextStep]);

  return (
    <Section title="Electricity price">
      <Alert severity="info">
        Electricity pricing has been moved to Charging Settings on the Evnex app. Download the app on
        {` `}
        <Link href="https://play.google.com/store/apps/details?id=com.evnexappconsumer" target="_blank">
          Android
        </Link>
        {` `}
        or
        {` `}
        <Link href="https://apps.apple.com/app/evnex/id1458071781" target="_blank">
          iOS
        </Link>
        {` `}
        to set electricity rates once you have created the charge point.
      </Alert>

      <Box pt={2} display="flex">
        <Button variant="contained" onClick={prevStep}>
          Back
        </Button>
        <Box mr="auto" />
        <Button color="primary" variant="contained" type="submit" disabled={isLoadingNextStep} onClick={goToNextStep}>
          {isLoadingNextStep ? <CircularProgress size={24} color="inherit" /> : 'Next'}
        </Button>
      </Box>
    </Section>
  );
};
