import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useGetOrganisationLocations, useLegacyGetOrganisationChargePoints } from '../../../../app/ApiGen';
import { useCurrentOrganisation } from '../../../../app/CurrentOrganisationProvider';
import { useDeveloperMode } from '../../../../app/DeveloperModeProvider';
import { NoChargePoints } from '../../../../components/NoContentPlaceholder/NoChargePoints';
import { NoLocations } from '../../../../components/NoContentPlaceholder/NoLocations';
import { Loading, Section, Workspace } from '../../../../design-system';
import { selectDerivedState } from '../../../../store/root';
import { normalise } from '../../../../utils/request';
import { ConnectorStatuses } from './components/ConnectorStatuses';
import { LocationsMap } from './components/LocationsMap';
import { OrganisationSummary } from './components/OrganisationSummary';
import { OrganisationSummary as OrganisationSummaryLegacy } from './components/OrganisationSummaryLegacy';

const DriverView: React.FC<{ orgSlug: string }> = ({ orgSlug }) => (
  <Grid item xs={12} md={12} lg={12}>
    <OrganisationSummaryLegacy orgSlug={orgSlug} />
  </Grid>
);

const OverviewView: React.FC = () => {
  const { loading, organisation } = useCurrentOrganisation();
  const { isDeveloperMode } = useDeveloperMode();
  const {
    params: { slug: orgSlug },
  } = useRouteMatch<{ slug: string }>();
  const { isDriver, isAdmin, isEvnexAdmin } = selectDerivedState({ organisation: { loading, current: organisation } });

  const { loading: loadingChargePoints, data: chargePoints } = useLegacyGetOrganisationChargePoints({
    orgSlug,
  });

  const { loading: loadingLocations, data } = useGetOrganisationLocations({
    orgSlug,
  });

  if (loading || loadingChargePoints || loadingLocations || !chargePoints || !organisation || !data) {
    return (
      <Workspace>
        <Loading />
      </Workspace>
    );
  }

  const locations = normalise(data);

  const locationsAndNoChargePoints = locations.length > 0 && chargePoints.data.items.length === 0;
  if (locationsAndNoChargePoints) {
    return (
      <Workspace maxWidth="lg">
        <Section>
          <Card>
            <Grid container direction="column">
              <Grid container item xs={12}>
                <Grid
                  item
                  xs={12}
                  style={{ padding: 30 }}
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Typography variant="h2" gutterBottom component="h3">
                    Welcome
                  </Typography>
                  {(isAdmin || isEvnexAdmin) && (
                    <Typography variant="h6" gutterBottom>
                      To get started, add a charge point
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <Grid container item direction="row" xs={12}>
                <Grid
                  container
                  item
                  xs={12}
                  direction="column"
                  justify="space-between"
                  alignItems="center"
                  style={{ paddingBottom: isDriver ? 30 : 0 }}
                >
                  <NoChargePoints orgSlug={orgSlug} isDriver={isDriver} />
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Section>
      </Workspace>
    );
  }

  const noLocationsAndNoChargePoints = locations.length === 0 && chargePoints.data.items.length === 0;
  if (noLocationsAndNoChargePoints) {
    return (
      <Workspace maxWidth="lg">
        <Section>
          <Card>
            <Grid container direction="column">
              <Grid container item xs={12}>
                <Grid
                  item
                  xs={12}
                  style={{ padding: 30 }}
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Typography variant="h2" gutterBottom component="h3">
                    Welcome
                  </Typography>
                  {(isAdmin || isEvnexAdmin) && (
                    <Typography variant="h6" gutterBottom>
                      To get started, add a charge point or location below
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <Grid container item direction="row" xs={12}>
                {isDriver ? (
                  <Grid
                    container
                    item
                    xs={12}
                    direction="column"
                    justify="space-between"
                    alignItems="center"
                    style={{ paddingBottom: 30 }}
                  >
                    <NoChargePoints orgSlug={orgSlug} isDriver={isDriver} />
                  </Grid>
                ) : (
                  <>
                    <Grid container item xs={6} direction="column" justify="space-between" alignItems="center">
                      <NoChargePoints orgSlug={orgSlug} isDriver={isDriver} />
                    </Grid>
                    <Grid container item xs={6} direction="column" justify="space-between" alignItems="center">
                      <NoLocations orgSlug={orgSlug} isDriver={isDriver} />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Card>
        </Section>
      </Workspace>
    );
  }

  return (
    <>
      <Grid container direction="column">
        <Grid item>
          <LocationsMap />
        </Grid>
        <Grid container item>
          <Workspace maxWidth="lg">
            <Grid container spacing={3}>
              {isDriver ? (
                <DriverView orgSlug={orgSlug} />
              ) : (
                <>
                  {isDeveloperMode ? (
                    <Grid item xs={12} md={12} lg={8}>
                      <OrganisationSummary orgSlug={orgSlug} orgId={organisation.id} />
                    </Grid>
                  ) : (
                    <Grid item xs={12} md={12} lg={8}>
                      <OrganisationSummaryLegacy orgSlug={orgSlug} />
                    </Grid>
                  )}
                  <Grid item xs={12} md={12} lg={4}>
                    <ConnectorStatuses orgSlug={orgSlug} />
                  </Grid>
                </>
              )}
            </Grid>
          </Workspace>
        </Grid>
      </Grid>
    </>
  );
};

export default OverviewView;
