import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import moment from 'moment-timezone';
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useGetOrganisationLegacyInsightsSummary } from '../../../../../../app/ApiGen';
import { DateLookbackSelect } from '../../../../../../components/DateLookbackSelect';
import { DataErrorHandler } from '../../../../../../components/ErrorHandler';
import { Card, Loading } from '../../../../../../design-system';
import { fullStateSelector } from '../../../../../../store/root';
import { TabContext } from '../OrganisationSummary/Context';
import { InsightTotals } from '../OrganisationSummary/InsightTotals';
import { ChartWrapper, Label, Tab, Tabs, Value } from '../OrganisationSummary/styled';
import { getChartData, getInsightTotals } from './utils';

type LookbackValue = 7 | 14 | 30;

const lookbackOptions: Record<LookbackValue, string> = {
  7: 'Last 7 days',
  14: 'Last 14 days',
  30: 'Last 30 days',
};

const lookbackOptionsData = Object.entries(lookbackOptions).map(([value, label]) => ({
  id: Number(value),
  label,
}));

export const OrganisationSummary: React.FC<{ orgSlug: string }> = ({ orgSlug }) => {
  const { palette } = useTheme();

  const { isAdmin, isEvnexAdmin, isFleetOperator } = useSelector(fullStateSelector);

  const [tab, setTab] = React.useState(0);
  const [lookback, setLookback] = React.useState<LookbackValue>(7);

  const { loading, error, data, refetch } = useGetOrganisationLegacyInsightsSummary({
    orgSlug,
    queryParams: { days: lookback, 'tz-offset': parseInt(`${moment().utcOffset() / 60}`, 10) },
  });

  if (loading) {
    return (
      <Card style={{ height: '100%' }}>
        <Loading py={3} height="100%" />
      </Card>
    );
  }

  if (error || !data) {
    return (
      <DataErrorHandler
        description="Unable to load organisation summary"
        refetch={refetch}
        error={error}
        type="embedded"
      />
    );
  }

  const rawTotals = getInsightTotals(data.data.items);

  const totals = new InsightTotals({
    powerUsage: rawTotals.powerUsage,
    sessions: rawTotals.sessions,
    cost: rawTotals.costs,
    duration: rawTotals.duration,
    carbonOffset: rawTotals.carbonOffset,
    totalCarbonUsage: rawTotals.totalCarbonUsage?.toLocaleString(),
  });

  return (
    <Card
      style={{ height: '100%' }}
      footer={
        <>
          <DateLookbackSelect
            options={lookbackOptionsData}
            selected={lookback}
            onSelect={(id) => setLookback(id as LookbackValue)}
          />
          <Box ml="auto" />
          {(isEvnexAdmin || isAdmin || isFleetOperator) && (
            <Button
              component={RouterLink}
              to={`/organisations/${orgSlug}/reports/sessions`}
              color="primary"
              variant="text"
              size="small"
              endIcon={<ChevronRightIcon />}
            >
              More reports
            </Button>
          )}
        </>
      }
    >
      <TabContext.Provider value={{ selected: tab }}>
        <Tabs>
          <Tab tabId={0} tabIndex={0} onClick={() => setTab(0)}>
            <Label>Electricity usage</Label>
            <Value>
              {totals.powerUsage} <small>kWh</small>
            </Value>
          </Tab>
          <Tab tabId={1} tabIndex={0} onClick={() => setTab(1)}>
            <Label>Electricity cost</Label>
            <Value>${totals.cost}</Value>
          </Tab>
          <Tab tabId={2} tabIndex={0} onClick={() => setTab(2)}>
            <Label>Sessions</Label>
            <Value>{totals.sessions}</Value>
          </Tab>
          <Tab tabId={3} tabIndex={0} onClick={() => setTab(3)}>
            <Label>Carbon offset</Label>
            <Value>
              {totals.carbonOffset} <small>kg</small>
            </Value>
          </Tab>
          {totals.formattedCarbonUsage && (
            <Tab tabId={4} tabIndex={0} onClick={() => setTab(4)}>
              <Label>Carbon use</Label>
              <Value>
                {totals.formattedCarbonUsage.value} <small>{totals.formattedCarbonUsage.unit}</small>
              </Value>
            </Tab>
          )}
        </Tabs>
        <ChartWrapper>
          <Bar
            options={{
              aspectRatio: 2.75,
              layout: { padding: 16 },
              plugins: {
                legend: { display: false },
              },
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
            }}
            data={getChartData(tab, data.data.items, palette.primary.main)}
          />
        </ChartWrapper>
      </TabContext.Provider>
    </Card>
  );
};
