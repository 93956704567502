interface InsightTotalsParams {
  powerUsage: string;
  sessions: string;
  cost: string;
  duration: string;
  carbonOffset: string;
  totalCarbonUsage?: string;
}

export class InsightTotals implements InsightTotalsParams {
  public readonly powerUsage: string;

  public readonly carbonOffset: string;

  public readonly sessions: string;

  public readonly cost: string;

  public readonly duration: string;

  public readonly totalCarbonUsage?: string;

  constructor(params: InsightTotalsParams) {
    this.powerUsage = params.powerUsage;
    this.sessions = params.sessions;
    this.cost = params.cost;
    this.duration = params.duration;
    this.carbonOffset = params.carbonOffset;
    this.totalCarbonUsage = params.totalCarbonUsage;
  }

  private formatToTwoDecimalPlaces(value: string): string {
    const num = parseFloat(value.replace(/,/g, ''));
    return num.toFixed(2).toLocaleString();
  }

  private parseNumber(value: string): number {
    return parseFloat(value.replace(/,/g, ''));
  }

  get formattedCarbonOffset(): string {
    const carbonOffsetNum = this.parseNumber(this.carbonOffset);
    return Number((carbonOffsetNum / 1000).toFixed(0)).toLocaleString();
  }

  get formattedCarbonUsage(): { value: string; unit: 'kg' | 'g' } | undefined {
    if (!this.totalCarbonUsage) return undefined;

    const carbonUsageNum = this.parseNumber(this.totalCarbonUsage);
    if (carbonUsageNum >= 1000) {
      return {
        value: this.formatToTwoDecimalPlaces((carbonUsageNum / 1000).toString()),
        unit: 'kg',
      };
    }
    return {
      value: this.formatToTwoDecimalPlaces(this.totalCarbonUsage),
      unit: 'g',
    };
  }
}
