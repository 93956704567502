import { RequestCreateThirdPartyChargePoint } from '../../app/ApiGen';
import {
  CreateChargePointWizardState,
  CreateThirdPartyChargePointWizardState,
} from '../../views/Organisations/Organisation/Create/CreateChargePoint/types';
import { ChargePointClaim } from '../api';

export function mapWizardStateToRequestCreateEvnexChargePoint(args: {
  organisationId: string;
  state: CreateChargePointWizardState;
}): ChargePointClaim {
  const { organisationId, state } = args;
  const { authorizationRequired, location, name, referenceId, serial } = state;
  return {
    configuration: { plugAndCharge: !authorizationRequired },
    locationId: location.id,
    metadata: { referenceId },
    name,
    organisationId,
    serial,
    tokenRequired: authorizationRequired,
  };
}

export function mapWizardStateToRequestCreateThirdPartyChargePoint(
  state: CreateThirdPartyChargePointWizardState,
): RequestCreateThirdPartyChargePoint {
  const { authorizationRequired, connectors, location, name, ocppChargePointId, referenceId } = state;
  return {
    data: {
      attributes: {
        connectors: connectors.map(({ amperage, connectorFormat, connectorType, powerType, voltage }) => ({
          connectorFormat,
          connectorType,
          maxAmperage: amperage,
          maxVoltage: voltage,
          powerType,
        })),
        name,
        ocppChargePointId: `${ocppChargePointId.prefix}-${ocppChargePointId.suffix}`,
        referenceId,
        tokenRequired: authorizationRequired,
      },
      relationships: { location: { data: { id: location.id, type: 'locations' } } },
      type: 'chargePoints',
    },
  };
}
