import { UserOrganisation } from '../../../../../../app/ApiGen';
import { ChargePoint } from '../../../../../../utils/api';

export const MenuItemLabels = [
  'Overview',
  'Sessions',
  'Charge scheduling',
  'Load management',
  'Logs',
  'Firmware',
  'Advanced',
  'Settings',
  'Diagnostics',
  'LLM Diagnostics',
  'Charging Configuration',
] as const;

export type MenuItemLabel = (typeof MenuItemLabels)[number];

type MenuItemMeta = {
  text: MenuItemLabel;
  path: string;
  shouldRender: boolean;
};

type MenuItemDefinitionParams = {
  organisation: UserOrganisation;
  chargePoint: ChargePoint;
  isEvnexAdmin: boolean;
  isAdmin: boolean;
};

export function menuItemsFactory(params: MenuItemDefinitionParams): MenuItemMeta[] {
  const { organisation, chargePoint, isEvnexAdmin, isAdmin } = params;
  return [
    {
      text: 'Overview',
      path: `/organisations/${organisation.slug}/charge-points/${chargePoint.id}/overview`,
      shouldRender: true,
    },
    {
      text: 'Sessions',
      path: `/organisations/${organisation.slug}/charge-points/${chargePoint.id}/sessions`,
      shouldRender: true,
    },
    {
      text: 'Charge scheduling',
      path: `/organisations/${organisation.slug}/charge-points/${chargePoint.id}/charge-scheduling`,
      shouldRender: true,
    },
    {
      text: 'Load management',
      path: `/organisations/${organisation.slug}/charge-points/${chargePoint.id}/load-management`,
      shouldRender: true,
    },
    {
      text: 'Logs',
      path: `/organisations/${organisation.slug}/charge-points/${chargePoint.id}/logs`,
      shouldRender: isEvnexAdmin || isAdmin,
    },
    {
      text: 'Firmware',
      path: `/organisations/${organisation.slug}/charge-points/${chargePoint.id}/firmware`,
      shouldRender: isEvnexAdmin || isAdmin,
    },
    {
      text: 'Advanced',
      path: `/organisations/${organisation.slug}/charge-points/${chargePoint.id}/advanced`,
      shouldRender: isEvnexAdmin || isAdmin,
    },
    {
      text: 'Settings',
      path: `/organisations/${organisation.slug}/charge-points/${chargePoint.id}/settings`,
      shouldRender: true,
    },
    {
      text: 'Diagnostics',
      path: `/organisations/${organisation.slug}/charge-points/${chargePoint.id}/diagnostics`,
      shouldRender: isEvnexAdmin,
    },

    {
      text: 'LLM Diagnostics',
      path: `/organisations/${organisation.slug}/charge-points/${chargePoint.id}/llm-diagnostics`,
      shouldRender: isEvnexAdmin,
    },
    {
      text: 'Charging Configuration',
      path: `/organisations/${organisation.slug}/charge-points/${chargePoint.id}/charging-configuration`,
      shouldRender: isEvnexAdmin,
    },
  ];
}
